import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveView from '../ResponsiveView';

import {getConcertsList} from '../../api/kapiClient';

import {getConcertDetails} from '../../api/kapiClient';

import {getFormattedDate, joinComposersString, joinSingleComposerString, decodeId} from '../../utils/helpers';
import {track} from '../../utils/trackingService';

import InfoCard from './InfoCard';

import ListItemInfoCard from '../../components/ListItemInfoCard';
import ArtistListDropdown from '../../components/ArtistListDropdown';
import ListItemTwoLineIcon from '../../components/ListItemTwoLineIcon';

import { withTranslation } from 'react-i18next';
import { capitalizeTitle } from '../../utils/helpers';


class InfoCardConcert extends ResponsiveView {

  constructor(props){
    super(props, true);

    console.log("props in InfoCardConcert: ",this.props);

    this.state = {
      viewData:null,
    };

  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.props.match.params.id != nextProps.match.params.id){
      this.props = nextProps;
    }
  }

  componentWillUnmount(){
    super.componentWillUnmount();
    if(this.props.setPoiHighlights) this.props.setPoiHighlights(null);
  }

  onDataReady(data){
    console.log("-> onDataReady ", data, this.props);

    this.setState({
      viewData:data,
    });

    if(data){
      if(this.props.onDataReady) this.props.onDataReady(data);
      if(this.props.setPoiHighlights && data.location && data.location.longitude) this.props.setPoiHighlights([ [data.location.longitude, data.location.latitude] ]);

      track('load-page', {pageId:'InfoCard', category:'Konzert', id:decodeId(this.props.match.params.id), title:data.title, composers:joinComposersString(data.works)});
    }
  }

  onViewResize(){
    this.setState({
      resize:true,
    })
  }

  onNavigateTo(path){
    console.log("onNavigateTo: ", path)
    this.props.history.push(path);
  }

  onBackButton(){
    this.props.history.goBack();
  }

  onMapButton(){
    this.props.onMapButton();
  }

  onShareInfoCard(){

    let sharingData = {
      url:window.location.origin+'/#'+this.props.location.pathname,
      title:this.state.viewData.title,
      text:this.state.viewData.orchestra.name,
    };

    this.props.onShareInfoCard(sharingData)
  }

  onProgramItemSelected(id){
    let path = '/discover/works/'+id;
    this.props.history.push(path);
  }

  onArtistItemSelected(id){
    let path = '/discover/artists/'+id;
    this.props.history.push(path);
  }

  renderProgramListItems(){

    if(!this.state.viewData.works) return;

    let items = [];

    for(let i=0; i<this.state.viewData.works.length;i++){

      let pItem = this.state.viewData.works[i];

      items.push(
        <ListItemTwoLineIcon
          key={i}
          id={pItem.id}
          title={pItem.title}
          subtitle={joinSingleComposerString(pItem.composer)}
          onItemSelected={this.onProgramItemSelected.bind(this)}
        />
      )

    }

    return items;
  }

  render() {

    const {t} = this.props;

    return (
      <div style={this.styles().view}>

        <InfoCard
          queryFuncList={getConcertsList}
          resultKeyList={'concerts'}
          queryFunc={getConcertDetails}
          loadMockData={false}
          resultKey={'concert'}
          title={t('infocard.concert')}
          onMapButton={this.props.onMapButton}
          onDataReady={this.onDataReady.bind(this)}
          pathname={this.props.location.pathname}
          id={this.props.match.params.id}
          total={337}
          type="IC_CONCERT"
          onNavigateTo={this.onNavigateTo.bind(this)}
          onBackButton={this.onBackButton.bind(this)}
          onShareInfoCard={this.onShareInfoCard.bind(this)}
          favouriteStorageData={
            this.state.viewData ?
            {
              title:this.state.viewData.title,
              subtitle:this.state.viewData.orchestra ? this.state.viewData.orchestra.name : '',
              imageUrl:this.state.viewData.imageUrl,
              link:this.props.location.pathname,
            } : null
          }
        >

          {this.state.viewData
            ? <div>

                <ListItemInfoCard
                  id={this.state.viewData.id}
                  title={this.state.viewData.title}
                  subtitle={joinComposersString(this.state.viewData.works)}
                  caption={this.state.viewData.orchestra ? this.state.viewData.orchestra.name : ''}
                  kwv={this.state.viewData.kwv}
                  dateString={getFormattedDate(this.state.viewData.date)}
                  wrapTitle={true}
                />

                {this.state.viewData.artists && this.state.viewData.artists.length == 1
                  ? <ArtistListDropdown
                      label={t('infocard.artist')+" ("+this.state.viewData.artists.length+")"}
                      artists={this.state.viewData.artists}
                      onItemSelected={this.onArtistItemSelected.bind(this)}
                    />
                  : null
                }
                {this.state.viewData.artists && this.state.viewData.artists.length > 1
                  ? <ArtistListDropdown
                      label={t('discover.artists')+" ("+this.state.viewData.artists.length+")"}
                      artists={this.state.viewData.artists}
                      onItemSelected={this.onArtistItemSelected.bind(this)}
                    />
                  : null
                }


                <div style={this.styles().programContainer}>

                  <div style={this.styles().listHeader}>{t('infocard.artist.program')}</div>

                  <div style={this.styles().list}>
                    {this.renderProgramListItems()}
                  </div>

                </div>


              </div>

            : null
          }

        </InfoCard>

      </div>
    );
  }

  styles(){

    return {
      programContainer:{
        textAlign:'left',
        padding:'10px',
      },
      listHeader: {
        color:'#191919',
        fontWeight:'700',
        fontSize:'14px',
        padding:'20px 10px 10px 10px',
      }

    }

  }
}

InfoCardConcert.propTypes = {
  onMapButton: PropTypes.func.isRequired,
  onDataReady: PropTypes.func,
  onShareInfoCard: PropTypes.func,
  setPoiHighlights: PropTypes.func,
};

export default withTranslation()(InfoCardConcert);