import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveView from '../views/ResponsiveView';


import Radium from 'radium';

// https://www.npmjs.com/package/react-tooltip/v/4.5.1
import ReactTooltip from 'react-tooltip';

import { withTranslation } from 'react-i18next';


class KWVNumber extends ResponsiveView {

  onViewResize() {
    return true;
  }


  toggleNavigationDisplay(newButtonState) {
    console.log("toggleNavigationDisplay ", newButtonState);
    this.setState({
      isMenuVisible: newButtonState,
    })
  }

  render() {
    const {t} = this.props;

    return (
      <div style={this.styles().container}>


          <span data-tip={t('kwvNumber.tooltip').replaceAll(". ", ". <br/>")}>
            
            <span style={this.styles().kwvNumber}>
              KWV: {this.props.kwv}
            </span>

          </span>


        <ReactTooltip place="right" multiline="true" />

      </div>
    );
  }

  styles() {

    return {

      container: {
        marginTop: '6px',
        marginBottom: '6px',
      },

      kwvNumber: {
        borderBottom: '1px dashed grey',
      }


    }

  }
}

KWVNumber.propTypes = {
  kwv: PropTypes.number,
};

export default withTranslation()(Radium(KWVNumber));