import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveView from './ResponsiveView';
import Radium from 'radium';

import { getParamValue, getFormattedDate, getFormattedLifeDates, joinComposersString, joinSingleComposerString, getCooperationString, getFirstCharInString } from '../utils/helpers';

import { getArtistsList } from '../api/kapiClient';
import { getConcertsList } from '../api/kapiClient';
import { getRecordingsList } from '../api/kapiClient';
import { getLocationsList } from '../api/kapiClient';
import { getComposersList } from '../api/kapiClient';
import { getWorksList } from '../api/kapiClient';
import { getOrchestrasList } from '../api/kapiClient';
import { getEventsList } from '../api/kapiClient';


import { storeApiResultInCache, isApiCacheExpired, getApiResultFromCache } from '../api/caching';

import LoadingIndicator from '../components/LoadingIndicator';
import ListSortToggle from '../components/ListSortToggle';
import ClusterListItem from '../components/ClusterListItem';
import ListItemInfoCard from '../components/ListItemInfoCard';
import ListItemInfoCardSimple from '../components/ListItemInfoCardSimple';
import SearchHeader from '../components/SearchHeader';
import ButtonRoundedWithIcon from '../components/ButtonRoundedWithIcon';

import icnBackButton from '../img/icn_back.svg';
import icnArrowDown from '../img/icn_arrow_down.svg';
import icnArrowUp from '../img/icn_arrow_up.svg';

import { track } from '../utils/trackingService';

class DiscoverListView extends ResponsiveView {

    constructor(props) {
        super(props);

        let sortBy = 'ALPHABETICAL';

        if (this.props.defaultSort) sortBy = this.props.defaultSort;
        if (getParamValue(this.props.location.search, 'sortBy')) sortBy = getParamValue(this.props.location.search, 'sortBy');

        this.state = {
            listData: null,
            alphabeticalData: null,
            numericData: null,
            filterQuery: '',
            selectedClusterData: null,
            selectedClusterKey: null,
            filteredListData: null,
            sortBy: sortBy, // NUMERIC, ALPHABETICAL
        };

        this.onDataReady = this.onDataReady.bind(this);

        console.warn("constructor for DiscoverListView");
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("New Props in DiscoverListView: ", nextProps);

        if (nextProps.location.pathname !== this.props.location.pathname) {

            this.props = nextProps;
            this.loadData();
            //this.onDataReady(this.state.listData, this.props.query);
        } else {
            this.props = nextProps;
        }



    }

    componentDidMount() {

        this.loadData();
    }

    loadData() {

        let queryFunc = null;
        let resultKey = null;
        let isMock = true;

        if (this.props.query === 'getArtistsList') { queryFunc = getArtistsList; resultKey = 'persons'; isMock = false; }
        if (this.props.query === 'getComposersList') { queryFunc = getComposersList; resultKey = 'persons'; isMock = false; }
        if (this.props.query === 'getConcertsList') { queryFunc = getConcertsList; resultKey = 'concerts'; isMock = false; }
        if (this.props.query === 'getEventsList') { queryFunc = getEventsList; resultKey = 'events'; isMock = false; }
        if (this.props.query === 'getLocationsList') { queryFunc = getLocationsList; resultKey = 'locations'; isMock = false; }
        if (this.props.query === 'getOrchestrasList') { queryFunc = getOrchestrasList; resultKey = 'orchestras'; isMock = false; }
        if (this.props.query === 'getRecordingsList') { queryFunc = getRecordingsList; resultKey = 'recordings'; isMock = false; }
        if (this.props.query === 'getWorksList') { queryFunc = getWorksList; resultKey = 'musicworks'; isMock = false; }

        console.log(`QueryFunc: ${queryFunc}`);

        //console.log("loadData ", queryFunc);

        if (queryFunc && !this.state.listData) {

            queryFunc(isMock).then(function (result) {

                if (result && result.data && result.data[resultKey]) {
                    console.log(this.props.query + " returned: ", result.data);

                    if (isMock) {
                        this.onDataReady(result.data[resultKey], this.props.query);
                    } else {

                        let convertedResult = [];

                        result.data[resultKey].edges.forEach(function (el) {
                            convertedResult.push(el.node);
                        });

                        this.onDataReady(convertedResult, this.props.query);
                    }

                } else if (result.errors) {
                    console.error('There was an error for query ' + this.props.query + ':', result.errors[0].message);
                    this.setState({ isLoading: false, errorMessage: 'Es ist ein Fehler aufgetreten.' });
                } else {
                    console.log(this.props.query + ' did not return any results', result);
                    this.setState({ isLoading: false, errorMessage: 'Leider konnten wir keine ' + this.props.title + ' finden.' });
                }

            }.bind(this), function (error) {
                console.log("There was a problem querying " + this.props.query + ": ", error);
            }.bind(this));

        } else {
            this.onDataReady(this.state.listData, this.props.query);
        }

    }


    onViewResize() {

    }

    onDataReady(data, queryId) {
        console.log("-> onDataReady ", data, queryId);

        let alphabeticalData;
        let numericData;

        if (isApiCacheExpired(queryId + '_clustered')) {
            console.log("CACHE EXPIRED");

            alphabeticalData = this.getAlphabeticalData(data);

            if (queryId !== "getLocationsList" && queryId != "getWorksList") {
                numericData = this.getNumericData(data);
            }


            console.log("alphabeticalData before storing: ", alphabeticalData);
            console.log("numericData before storing: ", numericData);
            storeApiResultInCache(queryId + '_clustered', { alphabeticalData: alphabeticalData, numericData: numericData });
        } else {
            alphabeticalData = getApiResultFromCache(queryId + '_clustered').alphabeticalData;

            if (queryId !== "getLocationsList" && queryId != "getWorksList") {
                numericData = getApiResultFromCache(queryId + '_clustered').numericData;
            }

            console.log("GET CACHE ", getApiResultFromCache(queryId + '_clustered'));
        }


        let clusterData = null;
        let selectedCluster = this.props.match.params.id;


        console.log("alphabeticalData: ", alphabeticalData);

        if (selectedCluster) {

            let check = Number(selectedCluster);
            console.log("selectedCluster: ", selectedCluster);
            console.log("check: ", check);
            console.log("selectedCluster is a string: ", isNaN(selectedCluster));

            // assign alphabeticalData if the selectedCluster is NaN
            clusterData = isNaN(selectedCluster) ? alphabeticalData[selectedCluster] : numericData[selectedCluster];
            console.log("clusterData: ", clusterData);
            track('load-page', { pageId: 'DiscoverCategoryList', category: this.props.title, cluster: selectedCluster });
        } else {
            track('load-page', { pageId: 'DiscoverCategoryList', category: this.props.title });
        }



        this.setState({
            listData: data,
            alphabeticalData: alphabeticalData,
            numericData: numericData,
            selectedClusterData: clusterData,
            selectedClusterKey: selectedCluster,
        }, function () {
            let neighbourKeys = this.getPrevAndNextClusterKeys();
            console.log("neighbourKeys: ", neighbourKeys);

            this.setState({
                prevClusterKey: neighbourKeys ? neighbourKeys.prevKey : null,
                nextClusterKey: neighbourKeys ? neighbourKeys.nextKey : null,
            }, function () {
                if (neighbourKeys && neighbourKeys.prevKey) this.scrollContainer.scrollTo(0, 69);
            })
        })
    }

    getAlphabeticalData(data) {

        let resultArray = {};

        for (let i = 0; i < data.length; i++) {

            let index;

            if (this.props.query === "getArtistsList" || this.props.query === "getComposersList") {
                index = getFirstCharInString(data[i].lastName).toUpperCase();
            } else if (this.props.query === "getOrchestrasList") {
                index = getFirstCharInString(data[i].name).toUpperCase();
            } else if (this.props.query === "getLocationsList" && (data[i].name || data[i].city)) {
                index = data[i].name ? getFirstCharInString(data[i].name).toUpperCase() : getFirstCharInString(data[i].city).toUpperCase();
            } else if (data[i].title) {
                index = getFirstCharInString(data[i].title).toUpperCase();
            } /*else {
        index = 'NONAME';
      }*/

            if (index && index.length > 0) {

                // store entry per index (first character in name/title)
                if (!resultArray[index]) resultArray[index] = [];
                resultArray[index].push(data[i]);

                // sort entries per index by year (starting with the earliest date)
                resultArray[index].sort(function (a, b) {
                    return a.year - b.year;
                });

            }

        }

        // sort indices in alphabetical order
        const ordered = {};
        Object.keys(resultArray).sort().forEach(function (key) {
            ordered[key] = resultArray[key];
        });

        return ordered;

    }

    getNumericData(data) {

        let resultArray = {};

        for (let i = 0; i < data.length; i++) {

            let index;

            if (this.props.query === "getArtistsList" || this.props.query === "getComposersList") {
                index = data[i].birthDate.year;
            } else {
                index = data[i].date ? data[i].date.year : 'n/a';
            }

            if (!index) {
                index = 0;
            }

            // store entry per year
            if (!resultArray[index]) resultArray[index] = [];
            resultArray[index].push(data[i]);


            resultArray[index].sort(function (a, b) {
                //return a.lastName.localeCompare(b.lastName); // bad performance

                // order items within the year alphabetically
                if (this.props.query === "getArtistsList" || this.props.query === "getComposersList") {
                    var nameA = a.lastName.toLowerCase(), nameB = b.lastName.toLowerCase();
                    if (nameA < nameB) //sort string ascending
                        return -1;
                    if (nameA > nameB)
                        return 1;
                    return 0;
                } else {
                    return a.month - b.month;
                }
            }.bind(this));

        }

        return resultArray;

    }

    getPrevAndNextClusterKeys() {

        if (!this.state.selectedClusterKey || !this.state.selectedClusterData) return;

        let isAlphabetical = isNaN(this.state.selectedClusterKey);
        let data = isAlphabetical ? Object.keys(this.state.alphabeticalData) : Object.keys(this.state.numericData);

        let prevKey;
        let nextKey;

        for (let i = 0; i < data.length; i++) {

            let key = data[i];

            if (key === this.state.selectedClusterKey) {
                console.log("Selected Key: ", key);
                if (i > 0) prevKey = data[i - 1];
                if (i < data.length - 1) nextKey = data[i + 1];
            }

        }

        return { prevKey: prevKey, nextKey: nextKey };

    }

    onUpdateFilterQuery(query) {

        if (!this.state.listData) return;

        // decide which data should be filtered
        let arrayToFilter = this.props.match.params.id ? this.state.selectedClusterData : this.state.listData;

        let filteredArray = arrayToFilter.filter(function (el) {

            // normalise query string so that it does not matter if user enters capitalised words or not
            query = query.toLowerCase();

            // iterate over composers in element
            if (el.composer || (el.musicWork && el.musicWork.composer)) {

                let composer = el.composer ? el.composer : el.musicWork.composer;

                if (composer.firstName && composer.firstName.toLowerCase().indexOf(query) > -1) return true;
                if (composer.lastName && composer.lastName.toLowerCase().indexOf(query) > -1) return true;

            }

            // iterate over composers in element
            if (el.works) {
                let composerMatches = el.works.filter(function (work) {
                    if (work.composer && work.composer.firstName && work.composer.firstName.toLowerCase().indexOf(query) > -1) return true;
                    if (work.composer && work.composer.lastName && work.composer.lastName.toLowerCase().indexOf(query) > -1) return true;
                });

                if (composerMatches.length > 0) return true;
            }

            if (el.title && el.title.toLowerCase().indexOf(query) > -1) return true;

            if (el.orchestra && el.orchestra.name && el.orchestra.name.toLowerCase().indexOf(query) > -1) return true;
            if (el.date && el.date.year && el.date.year.toString().indexOf(query) > -1) return true;

            if (el.name && el.name.toLowerCase().indexOf(query) > -1) return true;

            if (el.firstName && el.firstName.toLowerCase().indexOf(query) > -1) return true;
            if (el.lastName && el.lastName.toLowerCase().indexOf(query) > -1) return true;
            if (el.birthDate && el.birthDate.year && el.birthDate.year.toString().indexOf(query) > -1) return true;

            if (el.country && el.country.toLowerCase().indexOf(query) > -1) return true;
            if (el.city && el.city.toLowerCase().indexOf(query) > -1) return true;
            //if(el.deathDate && el.deathDate.year && el.deathDate.year.toString().indexOf(query) > -1) return true;

            return false;
        });

        this.setState({
            filterQuery: query,
            filteredListData: query ? filteredArray : null,
        }, function () {
            // as the section navigation buttons (top and bottom of the list) are removed when the list is filtered,
            // the list has to be scrolled to
            if (this.state.filteredListData) {
                this.scrollContainer.scrollTo(0, 0); // 0 when there's no navigation
            } else {
                this.scrollContainer.scrollTo(0, 69); // 69 when the buttons are displayed again (filter not active)
            }
        });

    }


    onBackButton() {
        this.props.history.goBack();
    }

    onSortToggle() {

        console.log("window.location.search: ", window.location.search);
        console.log("this.props.location: ", this.props);

        if (this.state.sortBy === "ALPHABETICAL") {
            this.setState({
                sortBy: "NUMERIC",
            });

            this.props.location.search = "sortBy=NUMERIC";

            this.props.history.replace({
                pathname: this.props.location.pathname,
                search: '?sortBy=NUMERIC'
            });
            //window.location.search = "sortBy=NUMERIC";
            //window.history.replaceState(null, null, "?sortBy=NUMERIC");
        } else {
            this.setState({
                sortBy: "ALPHABETICAL",
            });
            //window.location.search = "sortBy=ALPHABETICAL";
            //window.history.replaceState(null, null, "?sortBy=ALPHABETICAL");
            this.props.history.replace({
                pathname: this.props.location.pathname,
                search: '?sortBy=ALPHABETICAL'
            });
        }

    }

    onClusterSelected(selectedCluster) {

        console.log("onClusterSelected: ", this.props.match.url, '+', selectedCluster);
        this.setState({
            selectedClusterData: isNaN(selectedCluster) ? this.state.alphabeticalData[selectedCluster] : this.state.numericData[selectedCluster],
        });

        let path = this.props.match.url + '/' + selectedCluster;
        console.log("navigate to: ", path);
        this.props.history.push(path);
    }

    onInfoCardSelected(id) {

        let path = this.props.match.url.split('list')[0] + id;//decodeId(id);
        this.props.history.push(path);
        console.log("rootPath: ", path);
    }

    loadPreviousCluster() {

        let path = this.props.match.url.split('/' + this.state.selectedClusterKey)[0] + '/' + this.state.prevClusterKey;
        console.log("navigate to: ", path);

        this.setState({
            //listData:null,
        }, function () {

        });

        this.props.history.replace(path);

    }

    loadNextCluster() {
        let path = this.props.match.url.split('/' + this.state.selectedClusterKey)[0] + '/' + this.state.nextClusterKey;
        console.log("navigate to: ", path);
        this.setState({
            //listData:null,
            //update:true,
        }, function () {

        });

        this.props.history.replace(path);
    }

    onScrollContainer() {
        console.log("sroll");
    }

    renderClusterListItems() {

        if (!this.state.listData) return;

        let items = [];
        let data;

        if (this.state.sortBy === "ALPHABETICAL") {
            data = this.state.alphabeticalData;
        } else {
            data = this.state.numericData;
        }

        if (!data) { console.warn("No list data available for sortBy: ", this.state.sortBy); return; }

        let i = 0;

        for (var key in data) {
            let value = data[key];

            let item = (<ClusterListItem
                id={key}
                key={key}
                label={key}
                amount={value.length}
                onItemSelected={this.onClusterSelected.bind(this)}
                lastItem={i === Object.keys(data).length - 1}
            />);

            i++;

            items.push(item);

        }

        return items;

    }

    renderInfoCardListItems(itemData) {

        if (!itemData) return;

        //console.log("*** alphabeticalData: ", Object.keys(this.state.alphabeticalData));

        let items = [];

        for (let i = 0; i < itemData.length; i++) {

            let data = itemData[i];
            let item;

            switch (this.props.query) {

                case "getConcertsList":
                case "getRecordingsList":

                    //console.log("data.type: ", data.type);

                    item = (<ListItemInfoCard
                        key={i}
                        id={data.id}
                        title={data.title ? data.title : 'Ohne Titel'}
                        subtitle={this.props.query === "getConcertsList" ? joinComposersString(data.works) : joinSingleComposerString(data.musicWork.composer)}
                        kwv={data.kwv}
                        caption={data.orchestra ? data.orchestra.name : ''}
                        dateString={getFormattedDate(data.date)}
                        onItemSelected={this.onInfoCardSelected.bind(this)}
                        lastItem={i === itemData.length - 1}
                        label={data.type ? data.type : null}
                        wrapTitle={true}
                    />);

                    break;
                case "getArtistsList":
                case "getComposersList":

                    let name = data.firstName ? data.firstName + " " + data.lastName : data.lastName;

                    item = (<ListItemInfoCardSimple
                        key={i}
                        id={data.id}
                        title={name}
                        subtitle={getFormattedLifeDates(data.birthDate, data.deathDate, true)}
                        onItemSelected={this.onInfoCardSelected.bind(this)}
                        lastItem={i === itemData.length - 1}
                        imageUrl={data.imageUrl}
                        imageExpected={true}
                    />);

                    break;
                case "getOrchestrasList":

                    item = (<ListItemInfoCardSimple
                        key={i}
                        id={data.id}
                        title={data.name}
                        subtitle={getCooperationString(data)}
                        onItemSelected={this.onInfoCardSelected.bind(this)}
                        lastItem={i === itemData.length - 1}
                    />);

                    break;
                case "getLocationsList":

                    item = (<ListItemInfoCardSimple
                        key={i}
                        id={data.id}
                        title={data.name ? data.name : data.city}
                        subtitle={data.city + ' - ' + data.country}
                        onItemSelected={this.onInfoCardSelected.bind(this)}
                        lastItem={i === itemData.length - 1}
                    />);

                    break;
                case "getWorksList":

                    item = (<ListItemInfoCardSimple
                        key={i}
                        id={data.id}
                        title={data.title}
                        subtitle={data.composer ? joinSingleComposerString(data.composer) : ''}
                        onItemSelected={this.onInfoCardSelected.bind(this)}
                        lastItem={i === itemData.length - 1}
                    />);


                    break;
                default:
                    item = (<ListItemInfoCardSimple
                        key={i}
                        id={data.id}
                        title={data.title}
                        subtitle={getFormattedDate(data.date)}
                        onItemSelected={this.onInfoCardSelected.bind(this)}
                        lastItem={i === itemData.length - 1}
                    />);
                    break;

            }

            items.push(item);

        }

        return items;

    }

    render() {

        return (
            <div style={this.styles().view}>

                <div style={this.styles().container}>


                    <div style={this.styles().listHeader}>

                        <div style={this.styles().backButton}>
                            <img src={icnBackButton} style={{ display: 'block' }} alt="Icon:BackButton" onClick={this.onBackButton.bind(this)} />
                        </div>

                        <div>
                            {!this.props.match.params.id && !this.props.disableSort
                                ? <div style={this.styles().listSortToggleContainer}>
                                    <ListSortToggle
                                        onButtonClick={this.onSortToggle.bind(this)}
                                        isActive={this.state.sortBy !== "ALPHABETICAL"}
                                    />
                                </div>

                                : null
                            }

                            {this.props.title}
                            {this.props.match.params.id
                                ? <span><span style={this.styles().separator}>•</span><span style={this.styles().clusterLabel}>{this.props.match.params.id}</span> </span>
                                : null
                            }
                        </div>

                        <SearchHeader
                            onInputChange={this.onUpdateFilterQuery.bind(this)}
                            placeholder={'Suche in ' + this.props.title + (this.state.selectedClusterKey ? '> ' + this.state.selectedClusterKey : '')}
                        />
                    </div>

                    {!this.state.listData
                        ? <div>
                            {this.state.errorMessage
                                ? <div style={this.styles().errorMessage}>{this.state.errorMessage}</div>
                                : <div style={this.styles().container}>
                                    <LoadingIndicator label={'Liste wird geladen'} />
                                </div>
                            }
                        </div>
                        : null
                    }

                    {this.state.listData
                        ? <div style={this.styles().contentContainer} onScroll={this.onScrollContainer.bind(this)} ref={(container) => this.scrollContainer = container}>

                            <div style={this.styles().content}>
                                {/* display if a cluster has been selected and cluster list is displayed*/}
                                {this.state.selectedClusterData && !this.state.filteredListData
                                    ? <div>
                                        {this.state.prevClusterKey
                                            ? <div style={this.styles().clusterNavigationButton}>
                                                <ButtonRoundedWithIcon
                                                    onButtonClick={this.loadPreviousCluster.bind(this)}
                                                    label={this.state.prevClusterKey}
                                                    borderColor={'#E6E6E6'}
                                                    labelColor={'#4A4A4A'}
                                                    icon={icnArrowUp}
                                                    highlightColor={'#1C6ECC'}
                                                />
                                            </div>
                                            : null
                                        }
                                        {this.renderInfoCardListItems(this.state.selectedClusterData)}
                                        {this.state.nextClusterKey
                                            ? <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                                                <ButtonRoundedWithIcon
                                                    onButtonClick={this.loadNextCluster.bind(this)}
                                                    label={this.state.nextClusterKey}
                                                    borderColor={'#E6E6E6'}
                                                    labelColor={'#4A4A4A'}
                                                    icon={icnArrowDown}
                                                    highlightColor={'#1C6ECC'}
                                                />
                                            </div>
                                            : null
                                        }
                                    </div>
                                    : null
                                }

                                {/* display if user has entered a filter query (main list and cluster list) */}
                                {this.state.filteredListData
                                    ? <div>
                                        {this.state.filteredListData.length > 0
                                            ? this.renderInfoCardListItems(this.state.filteredListData)
                                            : <div style={this.styles().errorMessage}>Keine Ergebnisse gefunden.</div>
                                        }
                                    </div>
                                    : null
                                }

                                {/* display main list if neither a cluster has been selected nor a filter query has been entered*/}
                                {!this.state.selectedClusterData && !this.state.filteredListData
                                    ? this.renderClusterListItems()
                                    : null
                                }
                            </div>

                        </div>
                        : null
                    }

                </div>

            </div>
        );
    }

    styles() {

        return {
            container: {
                //background:'#fff',
                color: '#131313',
                //overflow:'hidden',
                width: '100%',
            },
            spinnerContainer: {
                padding: '50px 20px',
                textAlign: 'center',
            },
            contentContainer: {
                width: '100%',
                overflowY: 'scroll',
                height: this.state.prevClusterKey ? window.innerHeight - 61 + 'px' : null,
                //background:'#f00'
                //marginTop:this.state.prevClusterKey ? '-90px' : '-1px',
                //height:'100vh',
            },
            content: {
                width: '100%',
                marginTop: this.state.prevClusterKey ? null : '-1px',
                minHeight: this.state.prevClusterKey ? window.innerHeight + 'px' : null,
                //background:'#f0f',
            },
            listHeader: {
                height: '60px',
                borderBottom: '1px solid #E6E6E6',
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: '900',
                color: '#4A4A4A',
                lineHeight: '60px',
            },
            separator: {
                fontSize: '14px',
                padding: '0 6px',
            },
            clusterLabel: {
                color: '#DAC46D',
            },
            backButton: {
                position: 'absolute',
                left: '7px',
                top: '10px',
                cursor: 'pointer',
                padding: '8px',
                ':hover': {
                    background: '#E6E6E6',
                    borderRadius: '50%',
                },
                zIndex: 1,
            },
            listSortToggleContainer: {
                position: 'absolute',
                left: '55px',
                top: '13px',
                zIndex: 1,
            },
            errorMessage: {
                padding: '20px',
            },
            clusterNavigationButton: {
                marginTop: '10px',
                marginBottom: '10px',
            }
        }

    }
}

DiscoverListView.propTypes = {
    title: PropTypes.string,
    query: PropTypes.string,
    defaultSort: PropTypes.string,
    disableSort: PropTypes.bool
};


export default Radium(DiscoverListView);